<!-- 离线报警 -->
<template>
  <section class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <el-row class="content-box">
      <el-row class="table-box">
        <!-- 表格 -->
        <el-table border fit v-loading="loading" :data="tableData" highlight-current-row style="width: 100%" >
          <el-table-column type="index" label="序号" width="150" align="center"></el-table-column>
          <el-table-column prop="hitContent" label="消息内容" min-width="150" align="center"></el-table-column>
          <el-table-column prop="createTime" label="报警时间" min-width="110" sortable align="center"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" v-if="total"/>
      </el-row>
    </el-row>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { getAlarmPage } from "@/api/aiot/alarmManagement/alarmManagement"
export default {
  name: "offlineAlarm",
  data(){
    return{
      crumbs: new Map([['AIOT'], ['报警管理'], ['离线报警']]),
      loading:true,         // 表格加载效果
      tableData:[],         // 表格数据
      projectId:"-1",         

      total: 0,             // 表格数据总数
      limit: 10,            // 每页显示数
      page: 1,              // 当前页 默认第一页
    }
  },
  computed:{
    ...mapState(['hotelInfo'])
  },
  watch:{
    hotelInfo:{
      handler(newVal,oldVal){
          if(newVal.projectId !== oldVal.projectId){
            this.projectId = this.hotelInfo.projectId===undefined?'-1':this.hotelInfo.projectId;
            this.getPage()
          }
      },
      deep:true
    },
  },
  mounted() {
    this.projectId = this.hotelInfo.projectId===undefined?'-1':this.hotelInfo.projectId;
    this.getPage();
  },
  methods:{
    // 获取离线报警 分页列表
    getPage() {
      let data = {
        limit: this.limit,
        page: this.page,
        alarmType: 1,    // 报警类型：1:离线报警、2：飞房报警
        hotelProjectId: this.projectId,
      }
      getAlarmPage(data).then(res=>{
        if(res.success){
          this.loading = false;
          this.total = res.records.total;
          this.tableData = res.records.records;
        }
      })
    },
    // 改变每页数
    pageChange(num) {
      this.limit = num;
      this.getPage()
    },
    // 改变当前页
    handlePaging(num) {
      this.page = num;
      this.getPage()
    },
  }
}
</script>